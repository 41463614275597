import styled from "styled-components";
import {useTour} from "@reactour/tour";
import {useLocation, useNavigate} from "react-router-dom";
import {useStepsStore} from "../store/steps-store";
import {useEffect} from "react";
import {postEvent} from "@telegram-apps/sdk-react";
import {finishOnboarding} from "../api/requests/user-info";


interface Props {
    content: string;
}

export const stepsData = [
    {
        selector: '.posts',
        content: 'Это ваша новая стена, здесь люди могут писать вам сообщения, оставлять комментарии и рисовать графити. ✨',
        bypassElem: true,
    },
    {
        selector: '.main-input',
        content: 'Создавайте уникальные посты для вашей стены, привлекайте больше друзей и получайте больше активности для вашей странциы. 🤓`   ',
        bypassElem: true,
    },

    {
        selector: '.link-header',
        content: "Копируйте уникальный адрес своей страницы и отправляет друзьям, именно так они смогут вас найти и присоединиться к сервису. 📬",
        bypassElem: true,

    },
    {
        selector: '.profile',
        content: 'Заходите на страницу новых пользователей и добавляйте их в друзья, это повысит популярность вашей страницы и поможет вести более активный образ ведения стены. 💙',
        bypassElem: true,
    },
    {
        selector: '.reply',
        content: "У вас есть возможность отвечать на комментарии у себя на стене, а так же на стенах ваших друзей. 🥹",
        bypassElem: true,
    }
]

export const Tour = ({content}: Props) => {
    const {currentStep, setCurrentStep} = useTour()
    const navigate = useNavigate()

    const {setStep} = useStepsStore()
    const {redirectTo} = useStepsStore()


    return (
        <Container>
            <Header>
                <Row>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M3.28052 0H16.7195C18.5228 0 20 1.47717 20 3.28052V16.7195C20 18.5228 18.5228 20 16.7195 20H3.28052C1.47717 20 0 18.5228 0 16.7195V3.28052C0 1.47717 1.47717 0 3.28052 0Z"
                              fill="#2E5070"/>
                        <path
                            d="M13.6441 11.7616C13.6441 12.2136 13.5603 12.6097 13.3879 12.9545C13.2154 13.2947 12.9871 13.5789 12.6982 13.8026C12.3534 14.0682 11.9759 14.2639 11.5705 14.3758C11.1605 14.4923 10.6386 14.5482 10.0048 14.5482H6.35617V5.44287H9.57611C10.2471 5.44287 10.7551 5.46617 11.0952 5.52209C11.44 5.57335 11.7662 5.68052 12.0691 5.84362C12.3953 6.02069 12.6376 6.25368 12.8007 6.54725C12.9638 6.84082 13.043 7.18099 13.043 7.5631C13.043 8.00578 12.9312 8.40187 12.7075 8.74669C12.4885 9.09152 12.1763 9.34781 11.7849 9.51091V9.56216C12.3487 9.68332 12.7961 9.92097 13.1316 10.2844C13.4717 10.6479 13.6395 11.1418 13.6395 11.7616H13.6441ZM10.7131 8.0151C10.7131 7.87064 10.6758 7.71687 10.6013 7.5631C10.5267 7.40932 10.4102 7.29748 10.2471 7.22759C10.098 7.16235 9.92094 7.12507 9.71125 7.12041C9.50622 7.11575 9.19867 7.11109 8.7886 7.11109H8.63017V9.04026H8.91908C9.3105 9.04026 9.58077 9.0356 9.73921 9.02628C9.89764 9.01696 10.0561 8.97502 10.2238 8.89581C10.4056 8.81193 10.5314 8.69543 10.6013 8.54166C10.6712 8.39255 10.7038 8.21547 10.7038 8.0151H10.7131ZM11.2909 11.7243C11.2909 11.4401 11.235 11.2257 11.1185 11.0626C11.002 10.9042 10.8343 10.783 10.6059 10.7038C10.4661 10.6479 10.2751 10.6199 10.0328 10.6153C9.79047 10.6106 9.46428 10.606 9.05421 10.606H8.63483V12.8846H8.75598C9.35244 12.8846 9.76251 12.88 9.99084 12.8706C10.2192 12.8613 10.4522 12.8101 10.6898 12.7076C10.8948 12.619 11.0486 12.4885 11.1465 12.3115C11.2443 12.1391 11.2909 11.9433 11.2909 11.7243Z"
                            fill="white"/>
                    </svg>

                    <Title>
                        Отвечайте на комментарии
                    </Title>
                </Row>
                <Progress>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <circle cx="3" cy="3" r="3" fill="#446E97"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <circle cx="3" cy="3" r="3" fill="#446E97"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <circle cx="3" cy="3" r="3" fill="#446E97"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <circle cx="3" cy="3" r="3" fill="#446E97"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                        <circle cx="3" cy="3" r="3" fill="#446E97"/>
                    </svg>
                </Progress>
            </Header>
            <Content>
                {content}
            </Content>
            <Footer>
                <Skip onClick={async () => {
                    await finishOnboarding()
                    navigate(redirectTo || '/', {replace: true}); // This ensures the navigation replaces the current history entry
                }}>
                    Пропустить
                </Skip>
                <Next
                    onClick={async () => {

                        if (currentStep === 4) {
                            //@ts-ignore
                            ym(98348424,'reachGoal','onboarding_finished')
                            await finishOnboarding()
                            navigate(redirectTo || '/', {replace: true}); // This ensures the navigation replaces the current history entry
                        } else {
                            setCurrentStep(currentStep + 1)
                            setStep((prev) => prev + 1)
                        }
                    }}
                >
                    Далее
                </Next>
            </Footer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 343px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    background: #FFF;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #F5F5F5;
`

const Title = styled.div`
    color: #2E5070;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

`

const Progress = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    color: #1F1F1F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-top: 1px solid #F5F5F5;
`

const Skip = styled.div`
    color: #8E8E8E;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`

const Next = styled.div`
    color: #2E5070;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
`