import React, {useEffect, useMemo} from 'react';
import './App.css';
import {MainView} from "./components/views/main";
import {initNavigator} from '@telegram-apps/sdk-react';
import {BrowserRouter, createBrowserRouter, Route, Router, RouterProvider, Routes, useNavigate} from "react-router-dom";
import {CommentView} from "./components/views/comment";
import {UsersView} from "./components/views/users";
import {useIntegration} from "@telegram-apps/react-router-integration";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import {useIsRedirectCalledStore} from "./store/is-redirect-called-store";
import {authHandler} from "./api/requests/comment";
import {useTokenStore} from "./store/token-store";
import {UserView} from "./components/views/user";
import {useUser} from "./hooks/use-user";
import {TourMain} from "./components/views/fake/main";
import {useStepsStore} from "./store/steps-store";
import {Leaders} from "./components/views/leaders";
import {Search} from "./components/views/search";
import {useIsUnboarded} from "./api/requests/user-info";
import {fetcher} from "./api/apiClient";

function App() {
    const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);

    useEffect(() => {
        navigator.attach();
        return () => navigator.detach();
    }, [navigator]);


    const routes = [
        {
            path: '/',
            element: <MainView isAnotherUser={false}/>
        },
        {
            path: '/tour',
            element: <TourMain/>
        },
        {
            path: '/user/:id',
            element: <UserView isAnotherUser={true}/>
        },
        {
            path: '/leaders',
            element: <Leaders/>
        }, {
            path: '/search',
            element: <Search/>
        },
        {
            path: '/comment/:id',
            element: <CommentView/>
        }, {
            path: '/friends/:id',
            element: <UsersView/>
        }
    ]

    const {initDataRaw} = retrieveLaunchParams();
    const {token, setToken} = useTokenStore();

    useEffect(() => {
        if (!token && initDataRaw) {
            authHandler(initDataRaw).then((token) => {
                setToken(token.token);
            });
        }
    }, [initDataRaw]);


    if (!token) {
        return null
    }



    return (
        <Router navigator={reactNavigator} location={location}>
            <NavigationHandler/>
            <Routes>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path}
                           element={route.element}/>
                ))}
            </Routes>
        </Router>
    );
}

function NavigationHandler() {
    const navigate = useNavigate();
    const {initData} = retrieveLaunchParams();
    const {isRedirectCalled, setIsRedirectCalled} = useIsRedirectCalledStore()
    const {setRedirectTo, redirectTo} = useStepsStore()

    const user = useUser()

    useEffect(() => {

        async function handleRedirect() {


            const response = await fetcher('/myOnboarding', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const jsonResp = await response.json();

            if (!jsonResp?.isCompleted && initData && !redirectTo) {

                if (initData?.startParam && initData?.startParam?.replace(/^user/, '') !== user?.user?.id && initData?.startParam?.includes('user')) {
                    setRedirectTo(() => '/user/' + initData?.startParam?.replace(/^user/, ''))
                } else {
                    setRedirectTo(() => '/')
                }
                navigate(`/tour`);
            }

            if (initData?.startParam && !isRedirectCalled && user?.user?.id && !redirectTo && jsonResp?.isCompleted && initData?.startParam?.includes('user')) {
                setIsRedirectCalled(() => true)
                if (initData?.startParam?.replace(/^user/, '') !== user?.user?.id) {
                    setTimeout(() => {
                        navigate(`/user/` + initData?.startParam?.replace(/^user/, ''));
                    }, 1000)
                }
            }
        }

        handleRedirect().then()

    }, [initData?.startParam, navigate, user]);

    return null;
}

export default App;
