import styled, {keyframes} from "styled-components";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    createComment,
    editComment,
    removeComment,
    useCommentByID,
    useSWRComments,
    useTotalComments
} from "../../api/requests/comment";
import {Comment} from "../comment";
import {Header} from "../header";
import {useUser} from "../../hooks/use-user";
import React, {useEffect} from "react";
import {Loader} from "../loader";
import {Comment as IComent} from "../../api/requests/model";


const getTotalCommentsText = (count: number) => {
    if (count === 1) {
        return '1 комментарий'
    }
    if (count > 1 && count < 5) {
        return `${count} комментария`
    }
    return `${count} комментариев`
}

export const CommentView = () => {
    const [childrenState, setChildrenState] = React.useState<IComent[]>([]);
    const [parentState, setParentState] = React.useState<IComent | undefined>();
    const [total, setTotal] = React.useState(0);
    const [isSending, setIsSending] = React.useState(false);
    const {id} = useParams();
    const {user} = useUser()
    const navigate = useNavigate()
    const {data: totalComments} = useTotalComments({
        parentID: id
    });
    const {data: mainComment} = useCommentByID(id);
    const {data: childrens, mutate} = useSWRComments({
        limit: 300,
        skip: !id,
        parentID: id
    })


    useEffect(() => {
        if (mainComment) setParentState(mainComment)
    }, [mainComment])

    useEffect(() => {
        if (childrens) setChildrenState(childrens.items)
    }, [childrens])

    useEffect(() => {
        if (totalComments) setTotal(totalComments.count)
    }, [totalComments])

    const [replyId, setReplyId] = React.useState<string | null>(null);


    const inputRef = React.useRef<HTMLTextAreaElement>(null);


    const adjustHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, []);


    const onCreateComment = async (text: string) => {
        setIsSending(true)
        try {
            const resp = await createComment({
                text: text,
                parentID: replyId,
                userID: mainComment?.authorUser?.id
            });
            if (resp) {
                await mutate()
                setReplyId(null)
                setTotal((total) => total + 1)
                window.scrollTo({top: 0, behavior: 'smooth'});

            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsSending(false)
        }

    }

    const onEditComment = async (id: string, text: string) => {
        if (mainComment?.id === id) {
            setParentState({
                ...mainComment,
                text: text
            })
        } else {
            setChildrenState(childrenState?.map((comment) => {
                if (comment.id === id) {
                    return {
                        ...comment,
                        text: text
                    }
                }
                return comment
            }))
        }
        try {
            const resp = await editComment({
                text: text,
                id: id
            });

            if (resp) {

                await mutate()
            }
        } catch (e) {
        }
    }

    const onDeleteComment = async (id: string) => {
        if (mainComment?.id === id) {
            setParentState(undefined)
        } else {
            setChildrenState(childrenState?.filter((comment) => comment.id !== id))
        }
        setTotal((total) => total - 1)

        try {
            await removeComment(id);
        } catch (e) {
            console.log(e)
        }
    }


    if (!parentState) {
        return <Loader isFullPage/>
    }
    return (
        <Container>
            <Header photoURL={user?.photoURL}/>

            <SecondaryHeader>
                <Back

                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    Назад
                </Back>
                <Count>
                    {getTotalCommentsText(total + 1 || 0)}
                </Count>
            </SecondaryHeader>

            <CommentWrapper>
                <Comment
                    isChild={false}
                    onEdit={async (id, text) => {
                        await onEditComment(id, text)
                    }}

                    onReply={() => {
                        window.scrollTo({top: window.innerHeight, behavior: 'smooth'});
                        setReplyId(parentState?.id)
                    }}
                    onDelete={async () => {
                        await onDeleteComment(parentState?.id)
                    }}
                    {...parentState}
                />
                {childrenState.map((comment) => (

                    <Comment
                        onEdit={async (id, text) => {
                            await onEditComment(id, text)
                        }}
                        onDelete={async () => {
                            await onDeleteComment(comment.id)
                        }}
                        key={comment.id}
                        isChild


                        {...comment}
                    />
                ))}
                {replyId &&
                    <KContainer isCut={childrens?.items && childrens?.items?.length > 0}>
                        <InputContainer>
                            <Input ref={inputRef} onInput={adjustHeight}/>
                            <SendButton
                                onClick={async () => {
                                    //@ts-ignore
                                    ym(98348424,'reachGoal','send_text_comment')
                                    await onCreateComment(inputRef.current?.value || '');
                                    inputRef.current!.value = '';
                                    adjustHeight();
                                }}
                            >
                                {isSending ?
                                    (<Loader/>) :
                                    (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                          viewBox="0 0 24 24"
                                          fill="none">
                                        <path
                                            d="M14.6163 23.2175C14.4217 23.2174 14.2309 23.1632 14.0652 23.0609C13.8996 22.9587 13.7657 22.8124 13.6783 22.6385L10.5693 16.4175C10.4855 16.2493 10.4482 16.0617 10.4614 15.8742C10.4746 15.6868 10.5378 15.5062 10.6443 15.3515L15.6643 8.55146L8.86434 13.5695C8.70974 13.676 8.52939 13.7392 8.34208 13.7524C8.15477 13.7656 7.96734 13.7283 7.79934 13.6445L1.57634 10.5355C1.39426 10.4437 1.2429 10.3008 1.14081 10.1243C1.03873 9.9478 0.990346 9.74535 1.0016 9.54177C1.01285 9.33818 1.08324 9.1423 1.20416 8.97812C1.32507 8.81394 1.49125 8.6886 1.68234 8.61746L21.5873 1.28146C21.7751 1.21208 21.9788 1.19797 22.1744 1.24082C22.3699 1.28367 22.549 1.38167 22.6906 1.52321C22.8321 1.66476 22.9301 1.84391 22.973 2.03944C23.0158 2.23498 23.0017 2.4387 22.9323 2.62646L15.5973 22.5325C15.5267 22.7242 15.4015 22.8911 15.2371 23.0125C15.0727 23.1338 14.8764 23.2044 14.6723 23.2155L14.6163 23.2175Z"
                                            fill="#446E97"/>
                                    </svg>)
                                }
                            </SendButton>
                        </InputContainer>

                    </KContainer>
                }
            </CommentWrapper>

            <KeyBoardHelper/>

        </Container>
    )
}

const KeyBoardHelper = styled.div`
    width: 100px;
    display: flex;
    margin-top: 40vh;
`

const SecondaryHeader = styled.div`

    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #EEE;
    
`

const Back = styled.div`
    color: #446E97;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    cursor: pointer;
`


const Count = styled.div`
    color: #7C9AB6;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
`


const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #ffffff;
    overflow-x: hidden;


`


const CommentWrapper = styled.div`

    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    border-bottom: 1px solid #EEE;

`


const KContainer = styled.div<{ isCut?: boolean }>`
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    justify-content: center;
    box-sizing: border-box;
    background: #f1f1f1;
    position: relative;
    width: ${props => props.isCut ? 'calc(100% - 44px)' : '100%'};
    margin-left: ${props => props.isCut ? '44px' : '0'};
`;


const InputContainer = styled.div`
    display: flex;
    align-items: center;
    background: white;
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    gap: 4px;
`

const SendButton = styled.div`
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`

const Input = styled.textarea`
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    resize: none;
    border: none;
    overflow: hidden;
    min-height: 30px;
    height: auto;
    width: 100%;

    &::placeholder {
        color: #999;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &:focus {
        outline: none;
    }
`;

