import styled from "styled-components";
import React, {useEffect} from "react";
import {Loader} from "./loader";
import {Draw} from "./icons/draw";

interface Props {
    onComment?: (text: string) => void;
    isAnotherUser?: boolean;
    isLoading?: boolean;
    onOpenGraphity?: () => void;
}

export const MainInput = ({onComment, isAnotherUser, isLoading, onOpenGraphity}: Props) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const onFocus = () => {
            setIsFocused(true);
        };

        const onBlur = () => {
            setIsFocused(false);
        };

        if (inputRef.current) {
            inputRef.current.addEventListener("focus", onFocus);
            inputRef.current.addEventListener("blur", onBlur);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("focus", onFocus);
                inputRef.current.removeEventListener("blur", onBlur);
            }
        };
    }, [inputRef]);

    const adjustHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, []);


    return (
        <Container
            className={'main-input'}
        >
            <InputContainer>
                <Input ref={inputRef} onInput={adjustHeight}/>
                <SendButton
                    onClick={() => {
                        //@ts-ignore
                        ym(98348424,'reachGoal','send_text_comment')
                        if (inputRef.current?.value) {
                            if (onComment) {
                                onComment(inputRef.current?.value || '');
                            }
                            inputRef.current!.value = '';
                            adjustHeight();
                        }
                    }}
                >
                    {isLoading ?
                        (
                            <Loader/>
                        ) :
                        (

                            <>
                                {(!isFocused && !inputRef.current?.value) ?
                                    <Draw onClick={onOpenGraphity}/> :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
                                        <path
                                            d="M14.6163 23.2175C14.4217 23.2174 14.2309 23.1632 14.0652 23.0609C13.8996 22.9587 13.7657 22.8124 13.6783 22.6385L10.5693 16.4175C10.4855 16.2493 10.4482 16.0617 10.4614 15.8742C10.4746 15.6868 10.5378 15.5062 10.6443 15.3515L15.6643 8.55146L8.86434 13.5695C8.70974 13.676 8.52939 13.7392 8.34208 13.7524C8.15477 13.7656 7.96734 13.7283 7.79934 13.6445L1.57634 10.5355C1.39426 10.4437 1.2429 10.3008 1.14081 10.1243C1.03873 9.9478 0.990346 9.74535 1.0016 9.54177C1.01285 9.33818 1.08324 9.1423 1.20416 8.97812C1.32507 8.81394 1.49125 8.6886 1.68234 8.61746L21.5873 1.28146C21.7751 1.21208 21.9788 1.19797 22.1744 1.24082C22.3699 1.28367 22.549 1.38167 22.6906 1.52321C22.8321 1.66476 22.9301 1.84391 22.973 2.03944C23.0158 2.23498 23.0017 2.4387 22.9323 2.62646L15.5973 22.5325C15.5267 22.7242 15.4015 22.8911 15.2371 23.0125C15.0727 23.1338 14.8764 23.2044 14.6723 23.2155L14.6163 23.2175Z"
                                            fill="#446E97"/>
                                    </svg>
                                }

                            </>
                        )
                    }
                </SendButton>
            </InputContainer>
            {(!isFocused && !inputRef.current?.value) && <Placeholder
                onClick={() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }}
            >
                {isAnotherUser ? 'Написать ...' : 'Что у вас нового?'}
            </Placeholder>}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    justify-content: center;
    box-sizing: border-box;
    background: #f1f1f1;
    position: relative;
`;


const InputContainer = styled.div`
    display: flex;
    align-items: center;
    background: white;
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
    gap: 4px;
`

const SendButton = styled.div`
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`

const Input = styled.textarea`
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    box-sizing: border-box;
    resize: none;
    border: none;
    overflow: hidden;
    min-height: 30px;
    height: auto;
    width: 100%;

    &::placeholder {
        color: #999;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &:focus {
        outline: none;
    }
`;

const Placeholder = styled.div`
    color: #999;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translate(0, -50%);
`;

